import { forwardRef, useImperativeHandle, useState } from "react";
import { Modal } from "react-bootstrap";
import { ConsultaPadrao } from "../../../../components/ConsultaPadrao";
import { model_prospect_observacoes } from "../../../../models";
import { formatDataHora } from "../../../../commons/utils";

export const ProspectObservacao = forwardRef((props: any, ref: any) => {
    const [show, setShow] = useState(false);
    const [model, setModel] = useState<any>();

    const columns = [
        { field: 'contprospitemcadastro', header: 'Cadastro', body: (e: any) => formatDataHora(e.contprospitemcadastro) },
        { field: 'contprospitemobs', header: 'Observação' },
        { field: 'usuario.usunome', header: 'Usuário' },
        { field: 'functions', style: { minWidth: '7rem', width: '7rem' } }
    ];

    function closeModal() {
        setShow(false);
    }

    function openModal(_empdid: any, _prospid: any, _contprospid: any) {
        let _model = { ...model_prospect_observacoes };

        _model.api_get = _model.api_get + '?empid=' + _empdid + '&prospid=' + _prospid + '&contprospid=' + _contprospid;
        _model.base = {
            ..._model.base,
            empid: _empdid,
            contprospitemid: 0,
            contprospid: _contprospid,
            prospid: _prospid
        }

        setModel(_model);
        setShow(true);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    return (
        model &&
        <Modal show={show} onHide={closeModal} centered={false} size='xl' >
            <ConsultaPadrao
                model={model}
                columns={columns}
                scrollHeight='500px'
                sortfield={'contprospitemcadastro'}
                sortorder={-1}
            />
        </Modal>
    )
})