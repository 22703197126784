import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider/useAuth';
import { VbDataTable } from '../VbDataTable';
import { Column } from 'primereact/column';
import { Card } from 'react-bootstrap';
import { VbButton } from '../VbButton';
import { FaPlus } from "react-icons/fa6";
import { CadastroPadrao } from '../CadastroPadrao';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { VbSwitch } from '../VbSwitch';
import { toast } from 'react-toastify';
import { confirm } from '../VbConfirmation';
import { isMobileDevice } from '../../commons/utils';
import { CardTable, Header, Title, Icon } from './components';
import { COLOR_BLUE, COLOR_RED } from '../../theme/styles';
import { VbTooltip } from '../VbTooltip';

interface ConsultaPadraoProps {
    model: any;
    columns: any;
    functions?: any;
    details?: any;
    groupcolumn?: any;
    groupfooter?: any;
    groupheader?: any;
    sortfield?: any;
    sortorder?: any;
    headerfunctions?: any;
    prevdata?: any;
    scrollHeight?: any;
    defaultFilter?: any;
    disableEdit?: any;
}

export const ConsultaPadrao: React.FC<ConsultaPadraoProps> = forwardRef((props, ref) => {
    const auth = useAuth();

    const refCadastroPadrao = useRef<any>();

    const [data, setData] = useState<any>(props.prevdata ? props.prevdata : []);
    const [loading, setLoading] = useState<any>(props.prevdata ? false : true);
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(() => {
        if (!props.prevdata)
            refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function refresh() {
        if (props.prevdata)
            return;

        setLoading(true);

        auth.get(props.model.api_get).then((response: any) => {
            if (props.defaultFilter)
                setData(props.defaultFilter(response.data.dados))
            else
                setData(response.data.dados)
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
        });
    }

    async function deleta(row: any) {
        let body: any = {
            [props.model.key]: row[props.model.key],
        }

        if (props.model.hasEmpresa) {
            if (row.empresa)
                body.empid = row.empresa.empid
            else if (row.empid)
                body.empid = row.empid
        }

        if (props.model.subKey) {
            props.model.subKey.forEach((key: any) => {
                body[key] = row[key];
            });
        }

        if (await confirm('Deseja mesmo apagar este registro?')) {
            auth.deleta(props.model.api_delete, body).then(() => {
                toast.success('Registro excluído com sucesso.');
                refresh();
            }).catch((error: any) => {
                toast.error(error.response.data.mensagem ? error.response.data.mensagem : 'Erro ao concluir a operação.');
                console.log(error)
            })
        }
    }

    async function ativaInativa(row: any, ativo: boolean) {
        let body: any = { ...row };

        body[props.model.inactive_field] = ativo ? 1 : 0;

        auth.post(props.model.api_post, body).then(() => {
            toast.success('Registro ' + (ativo ? 'ativado' : 'inativado') + ' com sucesso.');
            refresh();
        }).catch((error: any) => {
            toast.error(error.response.data.mensagem ? error.response.data.mensagem : 'Erro ao concluir a operação.');
            console.log(error)
        })
    }

    function tableFunctions(row: any) {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props.functions &&
                    props.functions(row)?.map((item: any, i: number) => (
                        <VbTooltip key={i} title={item.label}>
                            <VbButton size='sm' color={item.color} className='m-0 me-2 p-1' style={{ borderRadius: '50%' }} onClick={() => item.click(row)} iconcenter={item.icon} />
                        </VbTooltip>
                    ))}

                {props.model.api_post && !props.disableEdit && <VbButton size='sm' color={COLOR_BLUE} className='m-0 me-2 p-1' style={{ borderRadius: '50%' }} onClick={() => refCadastroPadrao.current.openModal(row)} iconcenter={<CreateIcon />} />}
                {props.model.api_delete && <VbButton size='sm' color={COLOR_RED} className='p-1' style={{ borderRadius: '50%' }} onClick={() => deleta(row)} iconcenter={<DeleteOutlineIcon />} />}
            </div>
        )
    }

    function tableAtivoInativo(row: any) {
        return (
            <VbSwitch checked={row[props.model.inactive_field] === 1} onChange={(e: any) => ativaInativa(row, e.target.checked)} />
        )
    }

    return (
        <>
            <CardTable className={isMobileDevice() ? "m-2 mt-3 mb-4" : "m-4 mb-4"}>
                <Card.Body className="p-2">
                    {!props.prevdata &&
                        <Header>
                            <Title>
                                <Icon>{props.model?.icon}</Icon>
                                {props.model?.name}
                            </Title>
                            <div style={{ display: 'flex' }}>
                                {props.headerfunctions &&
                                    props.headerfunctions().map((item: any, i: number) => (
                                        <VbButton key={i} icon={item.icon} onClick={() => item.click()} style={{ marginRight: '8px' }}>{item.title}</VbButton>
                                    ))}
                                {props.model.base &&
                                    <VbButton icon={<FaPlus />} onClick={() => refCadastroPadrao.current.openModal()}>Novo</VbButton>
                                }
                            </div>
                        </Header>
                    }
                    <VbDataTable
                        className="m-0"
                        value={data}
                        loading={loading}
                        refresh={refresh}
                        expandedRows={expandedRows}
                        onRowToggle={(e: any) => setExpandedRows(e.data)}
                        rowExpansionTemplate={props.details}
                        rowGroupMode={props.groupcolumn ? "subheader" : undefined}
                        groupRowsBy={props.groupcolumn}
                        rowGroupFooterTemplate={props.groupfooter}
                        rowGroupHeaderTemplate={props.groupheader}
                        sortField={props.sortfield}
                        sortOrder={props.sortorder}
                        scrollHeight={props.scrollHeight}
                        model={props.model}
                    >
                        {props.details && <Column expander={true} style={{ fontSize: '12px', width: '2.5rem', marginBottom: '-10px' }} />}
                        {
                            props.columns.map((col: any, i: number) => (
                                <Column
                                    key={col.field}
                                    sortable={(col.notSortable || col.field === 'functions' || col.header === 'Ativo') ? false : true}
                                    field={col.field}
                                    align={isMobileDevice() ? 'right' : 'left'}
                                    header={col.header}
                                    body={col.field === 'functions' ? (e) => tableFunctions(e) : col.header === 'Ativo' ? (e) => tableAtivoInativo(e) : col.body}
                                    style={{
                                        ...col.style,
                                        fontSize: '12px',
                                        marginBottom: `${(i + 1) === props.columns.length ? '0px' : '-14px'}`,
                                        ...(col.autoWidth ? { width: window.innerWidth, whiteSpace: 'pre-wrap' } : {})
                                    }}
                                />
                            ))
                        }
                    </VbDataTable>
                </Card.Body>
            </CardTable>
            {props.model.base &&
                <CadastroPadrao ref={refCadastroPadrao} model={props.model} success={() => refresh()} />
            }
        </>
    )
})